import React, { PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import { Link } from '@chakra-ui/next-js'

import { ChevronDownIcon, ChevronUpIcon, Search2Icon } from '@chakra-ui/icons'
import {
    Avatar,
    Button,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    SimpleGrid,
    Stack,
    Text,
    useDisclosure,
    Box,
} from '@chakra-ui/react'
import { Troop } from '@/src/types'

const MOCK_TROOP_COLORS = [
    'red.300',
    'orange.300',
    'yellow.200',
    'green.200',
    'teal.100',
    'blue.100',
    'purple.100',
    'pink.100',
]

const MOCK_RECOMMENDED_TROOPS = [
    { id: 5, name: 'La DAO', color: 'blue.100', slug: '#' },
    { id: 6, name: 'Signless', color: 'gray.200', slug: '#' },
]

export interface TroopNavPopoverProps {
    navTitle?: string
    troopsList: Troop[]
}

const TroopNavPopover = ({ navTitle, troopsList }: PropsWithChildren<TroopNavPopoverProps>) => {
    const { isOpen, onClose, onToggle } = useDisclosure()
    const router = useRouter()

    const currentTroop = troopsList.find((troop) => troop.slug === router.query.slug)

    if (navTitle) return <Heading size={['lg', null, 'md']}>{navTitle ?? 'Troops!'}</Heading>
    else
        return (
            <Popover isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                    {navTitle ? (
                        <Heading size={['lg', null, 'md']}>{navTitle ?? 'Troops!'}</Heading>
                    ) : (
                        <Button
                            rightIcon={
                                <Box display="flex" alignItems="center">
                                    {isOpen ? (
                                        <ChevronUpIcon fontSize="lg" />
                                    ) : (
                                        <ChevronDownIcon fontSize="lg" />
                                    )}
                                </Box>
                            }
                            borderRadius="full"
                            display="flex"
                            justifyContent="center"
                            w={['50%', null, '40%']}
                            _hover={{ bgColor: 'gray.800' }}
                            bgColor="black"
                            textColor="white"
                            onClick={onToggle}
                        >
                            {currentTroop ? (
                                <Text noOfLines={1}>{currentTroop.full_name}</Text>
                            ) : (
                                'Select Troop'
                            )}
                        </Button>
                    )}
                </PopoverTrigger>
                <Portal>
                    <PopoverContent
                        borderColor="blackAlpha.300"
                        marginTop={1}
                        width={['90vw', null, '55vw', '40vw', '35vw']}
                        overflowY="hidden"
                        maxH={['87.5vh', null, '85vh', '77.5vh']}
                    >
                        <PopoverHeader
                            borderColor="blackAlpha.300"
                            borderBottom="none"
                            px={6}
                            pt={4}
                            pb={1}
                            fontSize="lg"
                            fontWeight="medium"
                        >
                            <InputGroup mb={4}>
                                <Input placeholder="Search Troop..." borderColor="blackAlpha.300" />
                                <InputRightElement>
                                    <Search2Icon color="blackAlpha.700" />
                                </InputRightElement>
                            </InputGroup>
                            <Heading as="h3" ml={2} size={['lg', null, null, 'md']}>
                                My Troops
                            </Heading>
                        </PopoverHeader>
                        <PopoverBody borderColor="blackAlpha.300" py={2} px={6} overflowY="scroll">
                            <Stack spacing={6}>
                                <Stack spacing={2}>
                                    <SimpleGrid columns={2} spacing={2}>
                                        {troopsList.map((troop, index) => (
                                            <Link
                                                key={`troop_${troop.id}`}
                                                href={`/t/${troop.id}`}
                                                w="100%"
                                                onClick={onClose}
                                            >
                                                <Button
                                                    rounded="full"
                                                    gap={1}
                                                    justifyContent="start"
                                                    textAlign="left"
                                                    backgroundColor={MOCK_TROOP_COLORS[index % 8]}
                                                    w="100%"
                                                    px={2}
                                                    py={6}
                                                    style={{
                                                        whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    <Avatar name={troop.full_name} size="sm" />
                                                    <Text noOfLines={2}>{troop.full_name}</Text>
                                                </Button>
                                            </Link>
                                        ))}
                                    </SimpleGrid>
                                </Stack>
                            </Stack>
                        </PopoverBody>
                        <PopoverFooter
                            borderTop="none"
                            display="flex"
                            flexDir="column"
                            px={8}
                            pt={2}
                            pb={[4, null, 8]}
                        >
                            <Stack gap={[2, null, 4]}>
                                <Heading as="h3" size={['lg', null, null, 'md']}>
                                    Recommended
                                </Heading>
                                <SimpleGrid columns={2} spacing={2}>
                                    {MOCK_RECOMMENDED_TROOPS.map((troop, index) => (
                                        <Link
                                            key={`troop_${troop.id}`}
                                            href={`${troop.slug}`}
                                            w="100%"
                                            onClick={onClose}
                                        >
                                            <Button
                                                rounded="full"
                                                gap={1}
                                                justifyContent="start"
                                                textAlign="left"
                                                backgroundColor={MOCK_TROOP_COLORS[index % 8]}
                                                w="100%"
                                                px={2}
                                                py={6}
                                                style={{
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                <Avatar name={troop.name} size="sm" />
                                                {troop.name}
                                            </Button>
                                        </Link>
                                    ))}
                                </SimpleGrid>
                                <Link
                                    href="/deploy-troop"
                                    w="full"
                                    display="flex"
                                    justifyContent="center"
                                    _hover={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Button
                                        rounded="full"
                                        py={[6, null, 5]}
                                        variant="black-outline"
                                        w={['100%', null, '60%', '50%']}
                                        fontSize={['lg', null, 'md']}
                                    >
                                        + Create Troop
                                    </Button>
                                </Link>
                            </Stack>
                        </PopoverFooter>
                    </PopoverContent>
                </Portal>
            </Popover>
        )
}

export default TroopNavPopover
