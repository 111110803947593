import React, { useEffect } from 'react'
import { ConnectKitButton, useSIWE, useModal as useConnectKitModal } from 'connectkit'
import { useRouter } from 'next/router'

import { trpc } from '../lib/trpc'

import { Link } from '@chakra-ui/next-js'
import { BellIcon, Search2Icon, SettingsIcon } from '@chakra-ui/icons'
import {
    Avatar,
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Icon,
    IconButton,
    Image,
    List,
    ListItem,
    useDisclosure,
} from '@chakra-ui/react'

import TroopNavPopover from './popover/TroopNavPopover'
import { SiweHookProps } from '../types'
import useGetUserTroops from '../hooks/useGetUserTroops'
import UserSetup from './UserSetup/UserSetup'

export const APPBAR_HEIGHT_PX = 60
export const NAVBAR_HEIGHT_PX = 80

export interface AppBarProps {
    title?: string
    navTitle?: string
}

const MenuDrawer = ({
    onSignoutHandler,
    currentUserUsername,
    avatarImage,
}: {
    onSignoutHandler: () => void
    currentUserUsername?: string
    avatarImage?: string
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef<HTMLButtonElement>(null)

    return (
        <>
            <Avatar
                _hover={{
                    cursor: 'pointer',
                }}
                size="sm"
                name={currentUserUsername}
                src={avatarImage ?? '/avatars/troopie-1.svg'}
                onClick={onOpen}
                left={0}
                position="absolute"
            />
            <Drawer
                size={['full', null, 'xs', null, 'sm']}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent pt={[8, null, 6]} pb={16}>
                    <DrawerCloseButton />
                    <DrawerHeader fontSize={['2xl']}>Settings</DrawerHeader>
                    <DrawerBody px={12}>
                        {currentUserUsername && (
                            <List spacing={4} fontSize={['lg']}>
                                <ListItem display="flex" alignItems="center">
                                    <Link
                                        href={`/u/${currentUserUsername}`}
                                        display="flex"
                                        w="full"
                                        alignItems="center"
                                        gap={2}
                                        onClick={onClose}
                                    >
                                        <Icon
                                            boxSize={5}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                                                clipRule="evenodd"
                                            />
                                        </Icon>
                                        My Profile
                                    </Link>
                                </ListItem>
                                <ListItem display="flex" alignItems="center">
                                    <Link
                                        href="/settings/notifications"
                                        display="flex"
                                        w="full"
                                        alignItems="center"
                                        gap={2}
                                        onClick={onClose}
                                    >
                                        <BellIcon boxSize={5} />
                                        Notifications
                                    </Link>
                                </ListItem>
                            </List>
                        )}
                    </DrawerBody>
                    <DrawerFooter px={12}>
                        <Button variant="black-outline" w="full" onClick={onSignoutHandler}>
                            Logout
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export const AppBar: React.FC<AppBarProps> = (props) => {
    const { push } = useRouter()
    const {
        data: siweUserData,
        isSignedIn,
        signOut,
    }: SiweHookProps = useSIWE({
        onSignOut: () => push('/'),
    })
    const address = siweUserData?.address as `0x${string}` | undefined

    const {
        data: user,
        refetch: refetchUpdatedUser,
        status: fetchUserStatus,
    } = trpc.users.getByWallet.useQuery(address!, {
        enabled: Boolean(address && isSignedIn),
    })

    const notSignedUp = fetchUserStatus === 'success' && !user

    const { openSIWE, setOpen } = useConnectKitModal({
        onConnect() {
            if (isSignedIn) {
                setOpen(false)
            }
        },
    })

    const {
        isErrorTroopMemberships,
        isLoadingTroopMemberships,
        isSuccessTroopMemberships,
        troopsMemberships,
    } = useGetUserTroops(address)

    return (
        <>
            <UserSetup notSignedUp={notSignedUp} refetchUser={refetchUpdatedUser} />
            <Box
                bg="white"
                color="black"
                position="fixed"
                top={0}
                width="100vw"
                height={`${APPBAR_HEIGHT_PX}px`}
                p={[2, null, 3, 4]}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={isSignedIn ? 'center' : 'space-between'}
                    w={['100%', null, '60%']}
                    px={2}
                    position="relative"
                >
                    {!isSignedIn && (
                        <Image
                            mt={[1, null, null, 1.5]}
                            height={['24px', null, null, '28px']}
                            src="/logos/troops-bw.svg"
                            alt="Troops logo"
                        />
                    )}
                    <Box display="flex" alignItems="center">
                        <ConnectKitButton.Custom>
                            {({ isConnected, show }) => {
                                return (
                                    <>
                                        {isSignedIn ? (
                                            <MenuDrawer
                                                onSignoutHandler={signOut}
                                                currentUserUsername={user?.username}
                                            />
                                        ) : (
                                            <Link variant="unstyled" href="/login">
                                                <Button variant="primary" px={[6, null, null, 8]}>
                                                    Login
                                                </Button>
                                            </Link>
                                        )}
                                    </>
                                )
                            }}
                        </ConnectKitButton.Custom>
                    </Box>
                    {isSignedIn && (
                        <TroopNavPopover
                            navTitle={props.navTitle}
                            troopsList={troopsMemberships ?? []}
                        />
                    )}
                </Box>
                <Box
                    borderBottom="2px solid transparent"
                    _after={{
                        content: '""',
                        position: 'absolute',
                        left: 4,
                        right: 4,
                        bottom: 0,
                        height: '2px',
                        background: props.navTitle ? 'black' : 'white',
                    }}
                    px={2}
                ></Box>
            </Box>
        </>
    )
}

export function BottomNavbar() {
    return (
        <Box
            display="flex"
            justifyContent="center"
            position="fixed"
            bottom={0}
            height={`${NAVBAR_HEIGHT_PX}px`}
            width="100vw"
            bgColor="whiteAlpha.900"
            color="blackAlpha.900"
        >
            <Flex
                mb={[4, null, 2]}
                w={['90%', null, '50%', '30%']}
                justifyContent="space-around"
                alignItems="center"
            >
                {/*
                    // TODO: Add links
                */}
                <Link variant="unstyled" href="/">
                    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                        <IconButton
                            isRound={true}
                            variant="outline"
                            colorScheme="gray"
                            aria-label="Done"
                            icon={
                                <svg viewBox="0 0 24 24" fill="gray" height="1.2rem" width="1.2rem">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M21 20a1 1 0 01-1 1H4a1 1 0 01-1-1V9.49a1 1 0 01.386-.79l8-6.222a1 1 0 011.228 0l8 6.222a1 1 0 01.386.79V20zm-2-1V9.978l-7-5.444-7 5.444V19h14z" />
                                </svg>
                            }
                        />
                    </Box>
                </Link>
                <Link variant="unstyled" href="#">
                    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                        <IconButton
                            isRound={true}
                            variant="outline"
                            colorScheme="gray"
                            aria-label="Done"
                            icon={<Search2Icon color="gray" h={4} w={4} />}
                        />
                    </Box>
                </Link>
                <Link variant="unstyled" href="/">
                    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                        <IconButton
                            isRound={true}
                            variant="solid"
                            colorScheme="pink"
                            bgGradient="linear(to-t, #FF8500, #FF00FF)"
                            aria-label="Done"
                            icon={
                                <svg
                                    viewBox="0 0 1024 1024"
                                    fill="white"
                                    height="1.5rem"
                                    width="1.5rem"
                                >
                                    <path d="M464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H212V212h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H612V212h200v200zM464 544H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H212V612h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H612V612h200v200z" />
                                </svg>
                            }
                        />
                    </Box>
                </Link>
                <Link variant="unstyled" href="#">
                    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                        <IconButton
                            isRound={true}
                            variant="outline"
                            colorScheme="gray"
                            aria-label="Done"
                            icon={
                                <svg
                                    fill="none"
                                    stroke="gray"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    viewBox="0 0 24 24"
                                    height="1.2rem"
                                    width="1.2rem"
                                >
                                    <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 01-3.46 0" />
                                </svg>
                            }
                        />
                    </Box>
                </Link>
                <Link variant="unstyled" href="/dm">
                    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                        <IconButton
                            isRound={true}
                            variant="outline"
                            colorScheme="gray"
                            aria-label="Done"
                            icon={
                                <svg
                                    fill="none"
                                    stroke="gray"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    viewBox="0 0 24 24"
                                    height="1.2rem"
                                    width="1.2rem"
                                >
                                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                    <path d="M22 6l-10 7L2 6" />
                                </svg>
                            }
                        />
                    </Box>
                </Link>
            </Flex>
        </Box>
    )
}

export const PageWithAppBar: React.FC<AppBarProps & { children: React.ReactNode }> = (props) => {
    return (
        <>
            <AppBar {...props} />
            <Box
                position="absolute"
                top={`${APPBAR_HEIGHT_PX}px`}
                sx={{
                    height: `calc(100svh - ${APPBAR_HEIGHT_PX}px - ${NAVBAR_HEIGHT_PX}px)`,
                    width: '100vw',
                    overflow: 'scroll',
                }}
            >
                {props.children}
            </Box>
            <BottomNavbar />
        </>
    )
}
