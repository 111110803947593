import { useState } from 'react'
import { useAccount, useDisconnect } from 'wagmi'
import { useSIWE } from 'connectkit'

import { trpc } from '@/src/lib/trpc'

import {
    Avatar,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    useToast,
} from '@chakra-ui/react'
import { uploadBytes, getDownloadURL, getStorage, ref } from 'firebase/storage'
import { FileUploader } from '../FileUploader/FileUploader'
import { EditUserAvatar } from '../User/EditUserAvatar'
import { User } from '@/src/types'
import { UseQueryResult } from '@tanstack/react-query'

export default function UserSetup({
    currentUser,
    isUserEditing = false,
    notSignedUp = false,
    onClose,
    refetchUser,
}: {
    currentUser?: User
    isUserEditing?: boolean
    notSignedUp?: boolean
    onClose?: () => void
    refetchUser: (options?: {
        throwOnError: boolean
        cancelRefetch: boolean
    }) => Promise<UseQueryResult>
}) {
    const [profileImg, setProfileImg] = useState<File & { preview: string }>()
    const { address } = useAccount()
    const { disconnect } = useDisconnect()
    const { isSignedIn }: { isSignedIn: boolean } = useSIWE()
    const isConnected = Boolean(address && isSignedIn)

    const toast = useToast()
    const { mutate: createUser, status: createUserStatus } = trpc.users.create.useMutation({
        onSuccess() {
            refetchUser()
            toast({
                title: 'Profile created successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        },
        onError(err) {
            toast({
                status: 'error',
                description: err.message,
            })
        },
    })

    const { mutate: updateUser, status: updateUserStatus } = trpc.users.updateByWallet.useMutation({
        onSuccess() {
            refetchUser()
            isUserEditing &&
                toast({
                    title: 'Profile updated successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            onClose?.()
        },
        onError(err) {
            toast({
                status: 'error',
                description: err.message,
            })
        },
    })

    const handleUpdateUser = () => {
        return updateUser({
            avatarImage: avatarImage ?? undefined,
            bio: bio.length > 0 ? bio : undefined,
            email: email.length > 0 ? email : undefined,
        })
    }

    const [avatarImage, setAvatarImage] = useState<string>(currentUser?.avatar_image ?? '')
    const [username, setUsername] = useState<string>(currentUser?.username ?? '')
    const [email, setEmail] = useState<string>(currentUser?.email ?? '')
    const [bio, setBio] = useState<string>(currentUser?.bio ?? '')

    // Get a reference to the storage service, which is used to create references in your storage bucket
    const storage = getStorage()
    // Create a reference to 'username-avatar.jpg'
    const avatarRef = ref(storage, `${username}-avatar.jpg`)

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isConnected && (notSignedUp || isUserEditing)}
            onClose={() => {}}
            scrollBehavior="inside"
            size={['full', null, 'lg', 'lg']}
        >
            <ModalOverlay bg="blackAlpha.800" />
            <ModalContent pt={8} pb={4}>
                {isUserEditing && <ModalCloseButton onClick={onClose} />}
                <ModalHeader px={[8, null, 12]} pb={0}>
                    {isUserEditing ? (
                        <Heading
                            lineHeight={1.1}
                            mb={[2, null, 4]}
                            fontSize={[null, null, null, null, '3xl']}
                        >
                            Edit your profile
                        </Heading>
                    ) : (
                        <>
                            <Heading
                                lineHeight={1.1}
                                mb={[2, null, 4]}
                                fontSize={[null, null, null, null, '3xl']}
                            >
                                Welcome to Troops
                            </Heading>
                            <Text fontSize="md" fontWeight="normal">
                                Please provide some details so the community can get to know you
                                better 🌟
                            </Text>
                        </>
                    )}
                </ModalHeader>
                <ModalBody
                    display={'flex'}
                    alignItems={'center'}
                    flexDirection={'column'}
                    px={[8, null, 16]}
                    pt={[6, null, null, 4]}
                    gap={[4, null, null, 3, 4]}
                >
                    <Flex w="100%" flexDirection="column" alignItems="center">
                        <Flex w={['60%', null, '40%', '32.5%', '40%']} justifyContent="start">
                            <FileUploader
                                accept={{ 'image/*': ['.png', '.jpeg'] }}
                                multiple={false}
                                maxSize={10000000}
                                noDrag={true}
                                InnerDropzone={
                                    currentUser?.avatar_image || profileImg?.preview ? (
                                        <Flex
                                            w={['100%']}
                                            rounded="lg"
                                            justifyContent="start"
                                            alignItems="start"
                                        >
                                            {currentUser?.avatar_image || profileImg?.preview ? (
                                                <Image
                                                    width="100%"
                                                    src={
                                                        profileImg?.preview ??
                                                        currentUser?.avatar_image
                                                    }
                                                    alt={`${currentUser?.username} profile image`}
                                                    rounded="lg"
                                                    aspectRatio={1}
                                                    objectFit="contain"
                                                    _hover={{ cursor: 'pointer' }}
                                                />
                                            ) : (
                                                <Avatar w="100%" h="100%" />
                                            )}
                                        </Flex>
                                    ) : (
                                        <EditUserAvatar preview={profileImg?.preview} />
                                    )
                                }
                                onDrop={(acceptedFiles, rejectedFiles) => {
                                    if (rejectedFiles.length > 0) {
                                        rejectedFiles.forEach(({ file, errors }) => {
                                            console.error(file.name, file.size, errors)
                                            errors.map((error) => {
                                                if (error.code === 'file-too-large') {
                                                    toast({
                                                        title: 'File too large',
                                                        status: 'error',
                                                        duration: 3000,
                                                        isClosable: true,
                                                    })
                                                }
                                                if (error.code === 'file-invalid-type') {
                                                    toast({
                                                        title: 'Invalid file type',
                                                        status: 'error',
                                                        duration: 3000,
                                                        isClosable: true,
                                                    })
                                                }
                                            })
                                        })
                                    } else {
                                        uploadBytes(avatarRef, acceptedFiles[0])
                                            .then((snapshot) => {
                                                return getDownloadURL(snapshot.ref)
                                            })
                                            .then((downloadURL) => {
                                                setAvatarImage(downloadURL)
                                                setProfileImg(() =>
                                                    Object.assign(acceptedFiles[0], {
                                                        preview: URL.createObjectURL(
                                                            acceptedFiles[0]
                                                        ),
                                                    })
                                                )
                                            })
                                    }
                                }}
                            />
                        </Flex>
                        <Text fontSize="sm">Click to change</Text>
                    </Flex>
                    {isUserEditing ? (
                        <Heading>{currentUser?.username}</Heading>
                    ) : (
                        <FormControl>
                            <FormLabel mb={1}>Choose a memorable username</FormLabel>
                            <Input
                                type="text"
                                placeholder="vbuttermilk"
                                isInvalid={!/^[A-Za-z0-9_]{1,256}$/.test(username)}
                                value={username}
                                onChange={(event) => {
                                    setUsername(event.target.value)
                                }}
                            />
                        </FormControl>
                    )}
                    <FormControl>
                        <FormLabel mb={1}>
                            {isUserEditing
                                ? 'Registered email'
                                : 'Can we contact you by email? (optional)'}
                        </FormLabel>
                        <Input
                            type="email"
                            placeholder={
                                isUserEditing ? 'No email registered' : 'vitamin@buttermilk.com'
                            }
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel mb={1}>
                            {isUserEditing
                                ? 'Your bio'
                                : 'Tell us a little bit about yourself! (optional)'}
                        </FormLabel>
                        <Textarea
                            placeholder={
                                isUserEditing
                                    ? 'Share something about you with the community!'
                                    : 'I like long walks on beaches on summer days'
                            }
                            value={bio}
                            onChange={(event) => {
                                setBio(event.target.value)
                            }}
                        />
                    </FormControl>
                    {isUserEditing ? (
                        <Box pt={[6, null, null, 4]} display="flex" justifyContent="center">
                            <Button
                                variant="troop-gradient"
                                onClick={() => handleUpdateUser()}
                                isLoading={updateUserStatus === 'loading'}
                                loadingText="Saving"
                                spinnerPlacement="end"
                            >
                                Save changes
                            </Button>
                        </Box>
                    ) : (
                        <>
                            <Box pt={[6, null, null, 4]} display="flex" justifyContent="center">
                                <Button
                                    isLoading={createUserStatus === 'loading'}
                                    borderRadius="full"
                                    bgGradient="linear(to-tr, #0000FF, #FF00FF)"
                                    color="white"
                                    size="lg"
                                    onClick={() => {
                                        createUser({
                                            username,
                                            email,
                                            bio,
                                            avatarImage: profileImg?.preview,
                                        })
                                    }}
                                >
                                    🚀 LFG! 🚀
                                </Button>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    onClick={() => {
                                        disconnect()
                                    }}
                                >
                                    Nevermind, sign me out
                                </Button>
                            </Box>
                        </>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
