import { Box, Flex, Image } from '@chakra-ui/react'
import { GRADIENT_DIR_ARR, TROOPS_GRADIENT_ARR } from '@/src/constants/TroopsPalette'

export function EditUserAvatar({
    preview,
    avatarImgUrl,
}: {
    preview?: string
    avatarImgUrl?: string
}) {
    return (
        <Flex
            w={['100%']}
            rounded="lg"
            // pl={[8, null, 16, 20, 24]}
            justifyContent="start"
            alignItems="start"
        >
            {preview ? (
                <Box position="relative" h="100%" w={['100%']} overflow="hidden" rounded="lg">
                    <Image
                        src={preview}
                        _hover={{ cursor: 'pointer' }}
                        alt="profile-img"
                        aspectRatio={1}
                        objectFit="cover"
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                            URL.revokeObjectURL(preview)
                        }}
                    />
                </Box>
            ) : (
                <Flex
                    w={['100%']}
                    _hover={{ cursor: 'pointer' }}
                    aspectRatio={1}
                    bgGradient={`linear(${GRADIENT_DIR_ARR[0]}, ${TROOPS_GRADIENT_ARR[0][0]}, ${TROOPS_GRADIENT_ARR[0][1]} )`}
                    rounded="lg"
                    pl={12}
                    pr={4}
                    pb={4}
                    justifyContent="end"
                    alignItems="end"
                >
                    <Image
                        width="65%"
                        src="/troopie-assets/troopie-eyes-black.svg"
                        alt="Placeholder image"
                        objectFit="contain"
                    />
                </Flex>
            )}
        </Flex>
    )
}
